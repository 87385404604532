<template>
  <master-layout :showBack="false" smallTitle="Le tue" bigTitle="Surveys">
    <!-- <div class="card mt-4">
      <div class="page-header">
        <div class="title-header">
          <span class="mb-2">Italian</span>
          <span class="font-bold">Surveys</span>
        </div>
        <div class="title-details">
          <div
            class="flex align-items-center text-14 mb-2"
            style="color: #656979"
          >
            <IonIcon class="mr-2" :icon="calendarClear" />
            <span> Survey duration</span>
          </div>
          <span class="text-14">02, Ago-Nov 2024</span>
        </div>
      </div>

      <div class="separator"></div>
      <div class="page-container">
        <div class="p-3">
          <UserCard
            v-for="user in users"
            :key="user.name"
            :user="user"
            v-model="user.selected"
            :disabled="true"
            @click="goToSurvey"
          />
        </div>
      </div>
    </div> -->

    <transition-group name="block-slide-in" :style="{ '--i': users.length }">
      <template v-for="(survey, i) in surveys" :key="i">
        <SurveyCard
          :style="{ '--i': i }"
          v-if="showList1 && showList2"
          :title-prefix="survey.titlePrefix"
          :title="survey.title"
          :survey-duration="survey.surveyDuration"
          :users="users"
        />
      </template>
    </transition-group>

    <!-- <SurveyCard
      title-prefix="Italian"
      title=" Survey "
      :survey-duration="'Jan-Apr 2025'"
      :users="users"
    />

    <SurveyCard
      title-prefix="French"
      title=" Survey "
      :survey-duration="'Jan-Apr 2025'"
      :users="users"
    />

    <SurveyCard
      title-prefix="Spanish"
      title=" Survey "
      :survey-duration="'Jan-Apr 2025'"
      :users="users"
    /> -->
  </master-layout>
  <!-- <master>
    <template #header> -->
  <!-- </template> -->

  <!-- </master> -->
</template>

<script setup>
import { useRouter } from "vue-router";

import { useGeneralStore } from "../../stores/useGeneralStore";

import master from "../pk_views/layout/master.vue";
import { IonIcon } from "@ionic/vue";
import { calendarClear } from "ionicons/icons";

import UserCard from "./components/UserCard.vue";
import SurveyCard from "./components/SurveyCard.vue";

import MasterLayout from "../../components/MasterLayout.vue";
import { nextTick, onMounted, ref } from "vue";

const router = useRouter();

const generalStore = useGeneralStore();

const showList1 = ref(false);
const showList2 = ref(false);

const surveys = [
  {
    titlePrefix: "Italian",
    title: " Survey ",
    surveyDuration: "Jan-Apr 2025",
  },
  {
    titlePrefix: "French",
    title: " Survey ",
    surveyDuration: "Jan-Apr 2025",
  },
  {
    titlePrefix: "Spanish",
    title: " Survey ",
    surveyDuration: "Jan-Apr 2025",
  },
];

const users = [
  {
    name: "John Doe",
    image: "https://i.pravatar.cc/100",
    selected: true,
  },
  {
    name: "Jane Doe",
    image: "https://i.pravatar.cc/200",
    selected: true,
  },
  {
    name: "John Smith",
    image: "https://i.pravatar.cc/300",
    selected: true,
  },
  {
    name: "Alice Johnson",
    image: "https://i.pravatar.cc/400",
    selected: true,
  },
  {
    name: "Bob Brown",
    image: "https://i.pravatar.cc/500",
    selected: false,
  },
  {
    name: "Charlie Davis",
    image: "https://i.pravatar.cc/600",
    selected: false,
  },
  {
    name: "Diana Evans",
    image: "https://i.pravatar.cc/700",
    selected: false,
  },
  {
    name: "Evan Green",
    image: "https://i.pravatar.cc/800",
    selected: false,
  },
  {
    name: "Fiona Harris",
    image: "https://i.pravatar.cc/900",
    selected: false,
  },
  {
    name: "George White",
    image: "https://i.pravatar.cc/1000",
    selected: false,
  },
  {
    name: "Hannah Moore",
    image: "https://i.pravatar.cc/1100",
    selected: false,
  },
  {
    name: "Ian Martin",
    image: "https://i.pravatar.cc/1200",
    selected: false,
  },
  {
    name: "Jessica Lee",
    image: "https://i.pravatar.cc/1300",
    selected: false,
  },
  {
    name: "Kevin Clark",
    image: "https://i.pravatar.cc/1400",
    selected: false,
  },
  {
    name: "Lily Walker",
    image: "https://i.pravatar.cc/1500",
    selected: false,
  },
  {
    name: "Michael Young",
    image: "https://i.pravatar.cc/1600",
    selected: false,
  },
  {
    name: "Natalie King",
    image: "https://i.pravatar.cc/1700",
    selected: false,
  },
  {
    name: "Oscar Hill",
    image: "https://i.pravatar.cc/1800",
    selected: false,
  },
  {
    name: "Paula Scott",
    image: "https://i.pravatar.cc/1900",
    selected: false,
  },
  {
    name: "Quinn Adams",
    image: "https://i.pravatar.cc/2000",
    selected: false,
  },
];

const getSurveys = async () => {
  setTimeout(async () => {
    await nextTick();

    showList1.value = true;
  }, 1000);
};

onMounted(async () => {
  await getSurveys();
  setTimeout(async () => {
    await nextTick();
    showList2.value = true;
  }, 1);
});
</script>

<style scoped lang="scss">
.title-header {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.title-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #e2e9f1;
}

.page-container {
  padding: 16px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.card {
  border-radius: 10px;
  background-color: white;
  padding: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  min-height: 100px;
}
</style>
