<template>
  <div class="user-card">
    <img :src="user.image" alt="User Image" class="user-image" />
    <div class="user-info">
      <p class="user-name">{{ user.name }}</p>
      <label class="checkbox-container">
        <input
          type="checkbox"
          :checked="checked"
          @change="onCheck"
          :disabled="disabled"
        />
        <span class="checkmark" :class="{ disabled: disabled }"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.checked = newValue;
    },
  },
  methods: {
    onCheck(event) {
      this.$emit("update:modelValue", event.target.checked);
    },
  },
};
</script>

<style scoped>
.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 10px;
}

.user-name {
  font-weight: 600;
}

.checkbox-container {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 2px solid #d1d5db;
  border-radius: 50%;
  transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.checkmark.disabled {
  opacity: 0.5;
}
</style>
